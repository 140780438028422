<template>
	<div>
		<div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
			<div class="cusmain-table-wrap">
				<esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
			</div>
		</div>

    <!-- 대표번호 설정 -->
    <ModalDnis
      v-if="modal.dnis.visible"
      :is-open="modal.dnis.visible"
      :dnis-data="modal.dnis.data"
      @saveModal="onSaveModal('dnis')"
      @closeModal="onCloseModal('dnis')"
    />
	</div>
</template>

<script>
import { isSuccess, formatDate } from '@/plugins/common-lib';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
import {DxButton} from "devextreme-vue/button";
import ModalDnis from "@/pages/cc/ivr/schedule/modal-dnis.vue";

export default {
	components: {
    ModalDnis,
    EspDxDataGrid,
	},
	data() {
		return {
      modal: {
        dnis: {
          visible: false,
          data: {},
        },
      },
			stylingMode: 'outlined', //outlined, underlined, filled
      dnisList: [], //대표번호 리스트
			dataGrid: {
				refName: 'scheduleGrid',
        keyExpr:'id',
				allowColumnResizing: true, //컬럼 사이즈 허용
        allowReordering: true, //inline속성 느낌
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				hoverStateEnabled: true,
        dataSourceDefaultSortColumn: '-regDt,+id', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순
        dataSource: [],
				// width:'200',                                     // 주석처리시 100%
				//height:'calc(100vh - 470px)',                     // 주석처리시 100%
				apiActionNm: {},
				showActionButtons: {
					// 그리드 버튼 노출 설정값
					select: false,
					update: false,
					customButtons: [
						{
							widget: 'dxButton',
							options: {
								icon: '',
                text: this.$_msgContents('COMPONENTS.ADD', { defaultValue: '추가' }),
								elementAttr: { class: 'btn_XS default filled add1' },
								width: 60,
								height: 30,
								onClick: () => this.onAddData(),
							},
							location: 'before',
						},
						{
							widget: 'dxButton',
							options: {
								icon: '',
                text: this.$_msgContents('COMPONENTS.DELETE', { defaultValue: '삭제' }),
								elementAttr: { class: 'btn_XS white light_filled trash' },
								width: 60,
								height: 30,
								onClick: () => this.onDeleteData(),
							},
							location: 'before',
						},
					],
				},
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: false,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				sorting: {
					mode: 'multiple', // single multiple
				},
				// scrolling:{                                      // 미사용시 주석처리
				//     mode: 'virtual'                              //스크롤 모드 : ['infinite', 'standard', 'virtual']
				// },
				remoteOperations: {
					filtering: false,
					sorting: true,
					grouping: false,
					paging: true,
				},
				paging: {
					// scrolling 미사용시만 적용됨
					enabled: true,
					pageSize: 10,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: true, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: true,
				},
				headerFilter: {
					visible: true,
				},
				editing: {
					allowUpdating: false,
					allowDeleting: false,
					allowAdding: false,
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
					mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				},
				columns: [
					{
						caption: '스케줄명',
            i18n: 'CC.WORD.SCHEDULE_NAME',
						dataField: 'scheduleNm',
						alignment: 'left', // left center right
						visible: true,
						sortOrder: 'none', // acs desc none
						allowHeaderFiltering: false,
						fixed: false, // 컬럼 fix 시 사용
						fixedPosition: 'left', // left or right
						cellTemplate: (container, options) => {
							const aTag = document.createElement('a');
							aTag.innerText = options.value;
							aTag.addEventListener('click', () => {
								this.onUpdateData(options.data.id);
							});
							container.append(aTag);
						},
					},
					{
						caption: '설명',
            i18n: 'CC.WORD.DESCRIPTION',
						dataField: 'description',
						alignment: 'left',
						visible: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
					},
          {
            caption: '대표번호',
            i18n: 'CC.WORD.MAIN_NUMBER',
            dataField: 'dnisNotices',
            width: '10%',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            calculateCellValue: (rowData) => {
              if (!rowData.dnisSchedules) return 0;
              return rowData.dnisSchedules.length;
            },
            cellTemplate: (container, options) => {
              if( options.value > 0 ) {
                //링크 클릭시 팝업
                let aTag = document.createElement('a');
                aTag.innerText = `${options.value}개`;
                aTag.addEventListener('click', () => {
                  this.onOpenModal('dnis', options.data); //대표번호 설정 팝업 오픈
                });

                container.append(aTag);
              }else {
                let button = new DxButton({
                  propsData: {
                    text: this.$_msgContents('COMPONENTS.SETTING', { defaultValue: '설정' }),
                    elementAttr: { class: 'btn_XS white light_filled' },
                    width: 60,
                    height: 30,
                    value: options.data.id,
                    onClick: () => {
                      this.onOpenModal('dnis', options.data); //인입그룹 설정 팝업 오픈
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              }
            },
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowFiltering: true,
            allowGrouping: false,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value'
            },
          },
					{
            caption: '수정자',
            i18n: 'COMPONENTS.MODIFIER',
						dataField: 'editId',
						alignment: 'center',
						visible: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
					},
					{
            caption: '수정일시',
            i18n: 'COMPONENTS.RECENT_MODIFY_DATE',
						dataField: 'editDt',
						alignment: 'center',
						visible: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						calculateCellValue: this.editDt,
					},
					{
            caption: '등록자',
            i18n: 'COMPONENTS.REGISTRANT',
						dataField: 'regId',
						alignment: 'center',
						visible: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
					},
					{
            caption: '등록일시',
            i18n: 'COMPONENTS.REGISTRATION_DATE',
						dataField: 'regDt',
						alignment: 'center',
						visible: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						calculateCellValue: this.regDt,
					},
				],
			},
		};
	},
	computed: {},
	methods: {
    /** @description: 팝업 열기
     * @param settingType 설정 타입(dnis)
     * @param settingData 모달로 넘길 설정 데이터 */
    onOpenModal(settingType, settingData) {
      this.modal[settingType].visible = true;
      this.modal[settingType].data = settingData;
    },
    /**
     * @description : 팝업 저장
     * @param settingType : 설정 타입(dnis)
     */
    async onSaveModal(settingType) {
      this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));

      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
      if( settingType === 'dnis' ){
        await this.selectDnisList();
      }

      this.$refs[this.dataGrid.refName].refreshData();
    },
    /** @description : 팝업 닫기
     * @param settingType : 설정 타입(dnis)
     **/
    onCloseModal(settingType) {
      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
		/** @description: 운영스케줄관리 grid refresh 이벤트 */
		refreshDataList() {
			this.$refs[this.dataGrid.refName].refreshData();
		},
		/** @description: 운영스케줄 등록 페이지 이동 */
		onAddData() {
			this.$router.push('/cc/ivr/schedule/config/index');
		},
		/** @description: 운영스케줄 수정 페이지 이동 */
		onUpdateData(id) {
			this.$store.commit('setDetailParams', { id: id });
			this.$router.push({ path: '/cc/ivr/schedule/config/index' });
		},
		/** @description: 운영스케줄 관리 리스트 조회 */
		selectDataList() {
			const vm = this;
			this.dataGrid.dataSource = new CustomStore({
				key: 'id',
				async load(loadOptions) {
          const params = vm.$_getDxDataGridParam(loadOptions);

					if (!params.sort) {
						params.sort = vm.dataGrid.dataSourceDefaultSortColumn;
					}

          const payload = {
						actionname: 'CC_SCHEDULE_LIST',
						data: params,
            useErrorPopup: true,
					};

					const res = await vm.CALL_CC_API(payload);
          let rtnData = {
            data: [],
            totalCount: 0,
          };
					if (isSuccess(res)) {
            const scheduleList = res.data.data;
            scheduleList.forEach(schedule => {
              const dnisSchedules = vm.dnisList.filter(dnis => dnis.scheduleId === schedule.id);
              if( dnisSchedules.length > 0 ) {
                schedule.dnisSchedules = dnisSchedules;
              }
            });
						rtnData = {
							data: scheduleList,
							totalCount: res.data.header.totalCount,
						};
						vm.$refs[vm.dataGrid.refName].totalCount = rtnData.totalCount;
					}
          return rtnData;
				},
			});
		},
    /** @description : 대표번호 리스트 조회 메서드 */
    async selectDnisList() {
      const payload = {
        actionname: 'CC_IVR_DNIS_LIST',
        useErrorPopup: true,
      };

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.dnisList = res.data.data;
      }
    },
		/** @description : 데이터 삭제 */
		async onDeleteData() {
			const selectedRowsData = this.$refs[this.dataGrid.refName].selectedRowsData;
      if (selectedRowsData.length === 0) {
        return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
      }

			//채널관리에 스케줄 관련 데이터가 있는지 체크
			const isCheckDnisSchedule = await this.checkDnisSchedule(selectedRowsData.map(d => d.id));
			if (isCheckDnisSchedule) {
				return this.$_Msg(this.$_msgContents('CC.MESSAGE.NO_DELETE_DNIS_SCHEDULE', { defaultValue : '대표번호에 게시 중인 운영 스케줄이 있어 삭제할 수 없습니다.'}));
			}

      const payload = {
        actionname: 'CC_SCHEDULE_DELETE',
        data: selectedRowsData,
        loading: true,
        useErrorPopup: true,
      };

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
        this.$refs[this.dataGrid.refName].refreshData();
      }
		},
		/** @description : 대표번호관리에 스케줄 관련 데이터가 있는지 체크 */
		async checkDnisSchedule(scheduleIds) {
			const payload = {
				actionname: 'CC_IVR_DNIS_LIST',
				data: { scheduleId: scheduleIds },
        useErrorPopup: true,
			};

			const res = await this.CALL_CC_API(payload);
			if (isSuccess(res)) {
        return res.data.data.length > 0;
			}
		},
		/** @description: 수정일시 출력 */
		editDt(rowData) {
			return formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
		},
		/** @description: 등록일시 출력 */
		regDt(rowData) {
			return formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
		},
	},
	created() {},
	async mounted() {
    await this.selectDnisList();
		await this.selectDataList();
	},
};
</script>

